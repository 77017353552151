import request from '@/api/request.js'

/**
 * @Description: 容器
 */

// 分页查询
export function pageWmsBox(data) {
    return request({
        url: '/api/wmsBox/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsBox(data) {
    return request({
        url: '/api/wmsBox/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsBox(data) {
    return request({
        url: '/api/wmsBox/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsBox(data) {
    return request({
        url: '/api/wmsBox/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsBox(data) {
    return request({
        url: '/api/wmsBox/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsBox(data) {
    return request({
        url: '/api/wmsBox/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsBox(data) {
    return request({
        url: '/api/wmsBox/batchDelete',
        method: 'POST',
        data
    })
}

